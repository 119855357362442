import React from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';

import { howDoWeStandOutProps } from './payload';

import * as styles from './how-do-we-stand-out.module.scss';

export const HowDoWeStandOut = ({ classNameForSection }) => {
  const { items } = howDoWeStandOutProps;
  return (
    <NewStandardSection classNames={classNameForSection} {...howDoWeStandOutProps}>
      <div className={styles.container}>
        {items.map((item, index) => (
          <div key={'how-do-we-stand-out-block-number' + index} className={styles.block}>
            <img loading="lazy" className={styles.image} src={item.image} alt={item.altImage} />
            <img loading="lazy" className={styles.imageHover} src={item.imageHover} alt={item.altImage} />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </NewStandardSection>
  );
};
