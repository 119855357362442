import React from 'react';
import image1 from './images/image1.svg';
import image2 from './images/image2.svg';
import image3 from './images/image3.svg';
import image4 from './images/image4.svg';
import image5 from './images/image5.svg';
import image6 from './images/image6.svg';
import image11 from './images/image11.svg';
import image12 from './images/image12.svg';
import image13 from './images/image13.svg';
import image14 from './images/image14.svg';
import image15 from './images/image15.svg';
import image16 from './images/image16.svg';

export const howDoWeStandOutProps = {
  title: 'How do we stand out?',
  isH3: true,
  id: 'experience-and-know-how-on-call',
  items: [
    {
      image: image1,
      imageHover: image11,
      altImage: 'dedicated teams',
      text: (
        <>
          <b>Dedicated teams</b> that can be deployed fast to your project and scaled if necessary.
        </>
      ),
    },
    {
      image: image2,
      imageHover: image12,
      altImage: 'deliver on time',
      text: (
        <>
          We know how to manage scope, so we <b>deliver on time.</b>
        </>
      ),
    },
    {
      image: image3,
      imageHover: image13,
      altImage: 'all hands on deck',
      text: (
        <>
          <b>All hands on deck:</b> Project Managers, Engineering Directors, and the CTO are here to help you.
        </>
      ),
    },
    {
      image: image4,
      imageHover: image14,
      altImage: 'flexibility',
      text: (
        <>
          <b>Flexibility</b> is our middle name. We adapt methods, frameworks and processes to your needs.
        </>
      ),
    },
    {
      image: image5,
      imageHover: image15,
      altImage: 'long-term relationships',
      text: (
        <>
          We focus on <b>long-term relationships</b> - speed dating is not our thing.
        </>
      ),
    },
    {
      image: image6,
      imageHover: image16,
      altImage: 'right level of process to succeed',
      text: (
        <>
          Not too much, not too little. Just the <b>right level of process to succeed.</b> That is our approach.
        </>
      ),
    },
  ],
};
