import React from 'react';

import featuredImage from '@images/software-development-for-startup.jpg';

import { go, cPlusPlus, python, rust, react, kubernetes, dpdk, p4 } from '@images/logos-techstack-bigger';

import chatDuskImage from '@commons/contact-button-image-section/images/image3.svg';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './software-development-for-startups.module.scss';

export const softwareDevelopmentForStartupsSEOProps = {
  title: 'Software development for startups – CodiLime | Strategic Partner',
  description:
    'Growing your business could be a challenge. We know how to support startups on their way to success. At CodiLime, we have been doing so since 2011.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const softwareDevelopmentHeaderProps = {
  title: (
    <>
      Software <br />
      development for <br />
      startups
    </>
  ),
  isBorder: false,
  classNames: {
    header: styles.headerStyles,
    innerWrapper: styles.headerInnerWrapper,
    title: styles.headerTitle,
  },
};

export const underHeaderSectionProps = {
  textPartOne:
    'At CodiLime we understand that to create exceptional and powerful products you don’t need just coders. You need top-notch engineers and thinkers, and a delivery-efficient process',
  textPartTwo: (
    <>
      . We have 11 years of experience in <b>software product development and network engineering.</b> We help
      technology-driven startups solve their technological challenges, strengthen their development teams, and build
      their software products.
    </>
  ),
  contactButtonLabel: 'Empower your startup',
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
  },
};

export const techStackProps = {
  sectionProps: {
    title: 'Our technology stack - this is how we play',
    subtitle: (
      <span>
        When it comes to technology, <b>we want the best of the best</b> - for us and for you. So we built a home for
        the best software and network engineers where they can learn, share knowledge and upskill. And this is their
        playground:
      </span>
    ),
    id: 'our-technology-stack',
  },
  threeColumnsOnMobile: true,
  defaultColor: '#00aeef',
  data: [
    go,
    cPlusPlus,
    python,
    rust,
    react,
    kubernetes,
    dpdk,
    p4,
    {
      link: paths.TECHNOLOGIES,
      textOnly: 'Check more',
    },
  ],
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Read more about our expertise',
    subtitle:
      'Looking for a reliable source of knowledge beneficial for your startup? Check out our blog where we write more about topics like: ',
    id: 'publications',
    isH3: true,
  },
  blocks: [
    {
      text: 'Product discovery—how to start smart with your app development',
      link: '/blog/product-discovery-how-to-start-smart-with-your-app-development/',
    },
    {
      text: 'SDLC methodologies—which one to choose for your project?',
      link: '/blog/sdlc-methodologies/',
    },
    {
      text: 'Everything you should know about MVP software development ',
      link: '/blog/mvp-software-development/',
    },
    {
      text: 'CI/CD pipeline: why it makes sense for your startup\n',
      link: '/blog/ci-cd-pipeline-for-your-startup/',
    },
    {
      text: 'Outsourcing software development in 2022: pros and cons',
      link: '/blog/outsourcing-software-development-pros-cons/',
    },
    {
      text: 'Three software development metrics for a startup environment',
      link: '/blog/three-software-development-metrics-for-a-startup-environment/',
    },
  ],
};

export const blocksInRowsWithSomeActiveProps = {
  sectionProps: {
    title: 'Experience and know-how on call',
    subtitle: (
      <>
        At the beginning of our journey, we were also in the startup position. Thanks to that, we understand your fears
        and doubts – we’ve been in your shoes and appreciate what you need at every stage of{' '}
        <b>growing your business and project.</b> We want to back you in winning the uncertain, complex, and ambiguous
        market, and this is how we do it:
      </>
    ),
    id: 'experience-and-know-how-on-call',
    classNames: {
      titleStyles: styles.blocksInRowsWithSomeActiveTitle,
      subtitleStyles: styles.blocksInRowsWithSomeActiveSubtitle,
    },
  },
  sections: [
    {
      title: 'Expertise in software development and network engineering',
      description: (
        <>
          We draw on our eleven years of experience to provide you with the best solutions for your complex project. We
          will brainstorm new approaches and features with you and{' '}
          <b>look at your software product with a fresh perspective.</b> You have our full engagement from the outset.
        </>
      ),
    },
    {
      title: 'Proof of Concept and Minimum Viable Product',
      description: (
        <>
          We will help you <b>validate your business ideas without burning up your budget.</b> We have UX/UI experts on
          board who will prepare measurable PoC concepts and experienced developers to code your vision into a working
          product.
        </>
      ),
    },
    {
      title: 'Cost-saving process',
      description: (
        <>
          Developing a new software product for a competitive market comes with numerous risks, and overspend can be the
          most critical among them. Long-term development costs can be hard to predict and can overrun the budget in no
          time if not managed from the outset. One way to make future development less expensive is to implement a fully
          automated CI/CD process. Our{' '}
          <b>DevOps experts will help you establish a smart and efficient CI/CD pipeline</b> that will pay for itself in
          time.
        </>
      ),
    },
    {
      title: 'Neighborhood-like experience',
      description: (
        <>
          Having a partner that can understand your goals and the context of your business is crucial. When we work
          together with our partners from the Bay Area, we want them to feel that we are close, a friend from across the
          street. That is why{' '}
          <b>
            we’ve built a friendly, seamless cooperation process backed by more than a decade of experience in
            partnering
          </b>{' '}
          with US-based tech companies.
        </>
      ),
    },
  ],
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      section: styles.contactButtonImageSection,
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
    },
  },
  title: (
    <>
      Intrigued? <span className={styles.secondPartOfText}>We are an open book</span>
    </>
  ),
  buttonTitle: "Let's chat",
  image: chatDuskImage,
  imageAlt: 'Get in touch and discover the knowledge within your data',
};

export const sliderWithOurValuesProps = {
  title: (
    <>
      Why else can you rely on us? <b>See our values.</b>
    </>
  ),
  altText: 'our values in developing software',
};
