import React from 'react';
import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { NewReadMore } from '@commons/new-read-more/NewReadMore';
import * as styles from './error-proof-process-section.module.scss';

export const ErrorProofProcessSection = ({ classNameForSection }) => {
  const textPartOne = (
    <span>
      Behind every excellent software product stands a team that can turn vision into design and code, and use them to
      build a meaningful user experience
    </span>
  );
  const textPartTwo = (
    <span>
      . Having <b>an experienced, process-wise development team</b> is even more critical for startups. Why? Because
      competition starts long before the first version of the product hits the market. It begins with questions: who
      will write the first line of code? And the next one?
      <br />
      <br />
      The talent pool is shallow, but the <b>right partner</b> can help you with that. At CodiLime, we’ve built a space
      where top-notch software engineers can prove themselves by working on technically complex projects and helping our
      clients boost their businesses.
    </span>
  );
  return (
    <NewStandardSection classNames={classNameForSection}>
      <div className={styles.block}>
        <h2 className={styles.heading}>Error-proof process & teams that deliver</h2>
        <div className={styles.paragraph}>
          <NewReadMore textPartOne={textPartOne} textPartTwo={textPartTwo} breakpoint="sm" />
        </div>
      </div>
    </NewStandardSection>
  );
};
