import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import {
  MobileHeaderLayoutB,
  DesktopHeaderLayoutB,
  TechStack,
  SectionUnderHeaderWithUnderline,
  BlocksRedirectingToResources,
  BlocksInRowsWithSomeActive,
  NewClientsTestimonials,
  MeetCodilimeSection,
  ContactButtonImageSection,
  SliderWithOurValues,
} from '@src/components/commons';
import { ErrorProofProcessSection, HowDoWeStandOut } from '@pages-impl/software-development-for-startups';
import {
  techStackProps,
  softwareDevelopmentForStartupsSEOProps,
  softwareDevelopmentHeaderProps,
  underHeaderSectionProps,
  blocksRedirectingToResourcesProps,
  blocksInRowsWithSomeActiveProps,
  contactButtonImageSectionProps,
  sliderWithOurValuesProps,
} from '@pages-impl/software-development-for-startups/payload';

import * as styles from './software-development-for-startups.module.scss';

export default function SoftwareDevelopmentForStartups(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Get tailored support for your software startup"
      {...props}
    >
      <SEO {...softwareDevelopmentForStartupsSEOProps} />
      <MobileHeaderLayoutB {...softwareDevelopmentHeaderProps} />
      <DesktopHeaderLayoutB {...softwareDevelopmentHeaderProps} />
      <SectionUnderHeaderWithUnderline {...underHeaderSectionProps} />
      <MeetCodilimeSection />
      <BlocksInRowsWithSomeActive {...blocksInRowsWithSomeActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <ErrorProofProcessSection classNameForSection={{ innerWrapper: styles.errorProofSectionInnerWrapper }} />
      <HowDoWeStandOut classNameForSection={{ innerWrapper: styles.standOutSectionInnerWrapper }} />
      <SliderWithOurValues {...sliderWithOurValuesProps} />
      <TechStack {...techStackProps} />
      <NewClientsTestimonials
        isNewStandardSection
        classNames={{ innerWrapper: styles.clientsTestimonialsSectionInnerWrapper }}
      />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
    </Layout>
  );
}
