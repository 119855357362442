// extracted by mini-css-extract-plugin
export var blocksInRowsWithSomeActiveSubtitle = "software-development-for-startups-module--blocks-in-rows-with-some-active-subtitle--e3054";
export var blocksInRowsWithSomeActiveTitle = "software-development-for-startups-module--blocks-in-rows-with-some-active-title--39447";
export var blueBlockSubtitle = "software-development-for-startups-module--blue-block-subtitle--5201d";
export var blueBlockTitle = "software-development-for-startups-module--blue-block-title--0981d";
export var contactButtonImageSection = "software-development-for-startups-module--contact-button-image-section--ec5f2";
export var contactButtonImageSectionInnerWrapper = "software-development-for-startups-module--contact-button-image-section-inner-wrapper--15285";
export var headerInnerWrapper = "software-development-for-startups-module--header-inner-wrapper--12a31";
export var headerStyles = "software-development-for-startups-module--header-styles--75b62";
export var headerTitle = "software-development-for-startups-module--header-title--4321f";
export var secondPartOfText = "software-development-for-startups-module--second-part-of-text--6272f";
export var titleBlueText = "software-development-for-startups-module--title-blue-text--d7653";
export var underHeaderInnerWrapper = "software-development-for-startups-module--under-header-inner-wrapper--cc789";